
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Global from '../layout/global';

/**
 * General site structure template used for all non-linked pages.
 */
const PagesTemplate = ({ data, path }) => {
	const post = data.mdx;
	let img = '';
	if (post.frontmatter.featuredimage) {
		img = post.frontmatter.featuredimage.childImageSharp.sizes.src;
	} else {
		img = '';
    }
    return (
		<Global
			title={ post.frontmatter.title }
            image={ img }
            url={ path }
            background={ data.mdx.frontmatter.background }
            keywords={ data.mdx.frontmatter.keywords }
			description={ post.frontmatter.description || post.excerpt }>
            <div className="g-dynamic-page">
                <MDXRenderer>{ data.mdx.body }</MDXRenderer>
            </div>
        </Global>
    );
};

export const pageQuery = graphql`
    query PageQuery($slug: String!) {
        mdx(fields: {slug: { eq: $slug }}) {
            body
            frontmatter {
                title
                date
                background
                description
                title
                keywords
				featuredimage {
					childImageSharp {
					    sizes(maxWidth: 500) {
					 	    ...GatsbyImageSharpSizes
					    }
					}
				}
            }
        }
    }
`;

export default PagesTemplate;
